
// Don't rename the ref or the animate.js code won't work
import animate from '~/mixins/animate.js';
export default {
  mixins: [animate],
  props: {
    delay: {
      type: Number,
      default: () => 0
    }
  }
};
