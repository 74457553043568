
import SVGCheck from '../../assets/svg/check.svg';

export default {
  components: { SVGCheck },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      counter: this.data.title,
      layoutShiftCompensator: 0
    };
  },
  mounted() {
    const obj = this.$refs.percentageCounter;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (this.layoutShiftCompensator <= 1) {
          this.animateCounter(obj, 0, this.data.title, 1500);
          this.layoutShiftCompensator++;
        }
      });
    });

    observer.observe(obj);
  },
  methods: {
    animateCounter(obj, start, end, duration) {
      let startTimestamp = null;

      const step = (timestamp) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        this.counter = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }
  }
};
